import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { BrowserRouter, Link, Outlet, Route, Routes } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";

function HomeComponent() {
  return <h1>Ana sayfa</h1>;
}

function AboutComponent() {
  return <h1>Hakkımda</h1>;
}

function LayoutComponent() {
  return (
    <>
      <nav>
        <ul>
          <li>
            <Link to="/">Ana Sayfa</Link>
          </li>
          <li>
            <Link to="/about">Hakkımda</Link>
          </li>
          <li>
            {" "}
            <Link to="/login">Giriş</Link>
          </li>
        </ul>
      </nav>
      <Outlet />
    </>
  );
}

function LoginComponent() {
  return <h1>Girişt</h1>;
}

function AppComponent() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<LayoutComponent />}>
              <Route index element={<HomeComponent />}></Route>
              <Route path="about" element={<AboutComponent />}></Route>
              <Route path="login" element={<LoginComponent />}></Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <AppComponent></AppComponent>
  </>
);

reportWebVitals();
